import React from 'react';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';

import { getCategories } from '../api/category';

const NoOptionsMessage = (props) => {
	const { t } = useTranslation();

	return (
		<components.NoOptionsMessage {...props}>
			{t('No categories found')}
		</components.NoOptionsMessage>
	);
};

const CategorySelect = ({ value, placeholder, onChange }) => {
	const { t } = useTranslation();

	const loadOptions = async (inputValue) => {
		const query = { name: inputValue, sort_by: 'name,1' };
		const res = await getCategories(query);
		const data = res.data.map((f) => ({ value: f._id, label: f.name }));
		return data;
	};

	return (
		<AsyncSelect
			className="select-container"
			classNamePrefix="select"
			placeholder={placeholder || t('Select...')}
			isClearable={true}
			cacheOptions={true}
			defaultOptions={true}
			value={value}
			components={{ NoOptionsMessage }}
			loadOptions={loadOptions}
			onChange={onChange}
		/>
	);
};

export default CategorySelect;
