import React from 'react';
import isElectron from 'is-electron';
import thunk from 'redux-thunk';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { createHashHistory, createBrowserHistory } from 'history';
import { ToastContainer } from 'react-toastify';
import { I18nextProvider } from 'react-i18next';

import i18n from './locales';
import AppRoute from './AppRoute';
import reducer from './reducers';
import Updater from './components/Updater';
import useDarkMode from './hooks/useDarkMode';
import { getCurrentUser } from './utils/user';

const currentUser = getCurrentUser();
const menuIsOpen = localStorage['menuIsOpen'];
const folderIsOpen = localStorage['folderIsOpen'];
const initialState = {
	user: currentUser,
	menuIsOpen: menuIsOpen ? JSON.parse(menuIsOpen) : undefined,
	folderIsOpen: folderIsOpen ? JSON.parse(folderIsOpen) : undefined,
};

let store;
let history;
if (isElectron()) {
	history = createHashHistory();
	store = createStore(reducer, initialState, compose(applyMiddleware(thunk)));
} else {
	history = createBrowserHistory();
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	store = createStore(reducer, initialState, composeEnhancers(applyMiddleware(thunk)));
}

const crawlUpDomForAnchorTag = (node, e) => {
	if (!node) {
		return;
	} else if (node.nodeName === 'A') {
		const href = node.getAttribute('href');
		if (href && !href.includes('#/') && isElectron()) {
			e.preventDefault();
			window.ipcRenderer.send('open-external-window', href);
		} else {
			return;
		}
	} else {
		// need to pass the click event down through the recursive calls so we can preventDefault if needed
		return crawlUpDomForAnchorTag(node.parentNode, e);
	}
};

if (isElectron()) {
	document.body.addEventListener('click', (e) => {
		crawlUpDomForAnchorTag(e.target, e);
	});
}

const App = () => {
	const theme = useDarkMode();

	return (
		<Provider store={store}>
			<I18nextProvider i18n={i18n}>
				<Updater />
				<Router history={history}>
					<AppRoute />
				</Router>
				<ToastContainer position="top-center" theme={theme} />
			</I18nextProvider>
		</Provider>
	);
};

export default App;
