import fetch from '../utils/fetch';
import isDispatch from '../utils/isDispatch';

export const getInterests = async (dispatch, params) => {
	if (!isDispatch(dispatch)) {
		params = dispatch;
	}
	const res = await fetch('GET', '/interests', null, params);
	if (isDispatch(dispatch)) {
		dispatch({
			interests: res.data,
			type: 'BATCH_UPDATE_INTERESTS',
		});
	} else {
		return res;
	}
};

export const addInterest = (data) => {
	return fetch('POST', '/interests', data);
};

export const updateInterest = (interestID, data) => {
	return fetch('PUT', `/interests/${interestID}`, data);
};

export const deleteInterest = (interestID) => {
	return fetch('DELETE', `/interests/${interestID}`);
};
