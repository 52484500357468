import fetch from '../utils/fetch';

export const getPlans = (params) => {
	return fetch('GET', '/plans', null, params);
};

export const getPlan = (planID) => {
	return fetch('GET', `/plans/${planID}`);
};

export const addPlan = (data) => {
	return fetch('POST', '/plans', data);
};

export const updatePlan = (planID, data) => {
	return fetch('PUT', `/plans/${planID}`, data);
};

export const deletePlan = (planID) => {
	return fetch('DELETE', `/plans/${planID}`);
};
