import fetch from '../utils/fetch';

export const getArticles = async (dispatch, params, cancelToken) => {
	const res = await fetch('GET', '/articles', null, params, null, cancelToken);
	dispatch({
		articles: res.data,
		type: 'BATCH_UPDATE_ARTICLES',
	});
};

export const getArticleById = async (dispatch, articleID, params, cancelToken) => {
	const res = await fetch(
		'GET',
		`/articles/${articleID}`,
		null,
		params,
		null,
		cancelToken,
	);
	dispatch({
		article: res.data,
		type: 'UPDATE_ARTICLE_CONTENT',
	});
};

export const removeArticle = async (dispatch, type, articleID) => {
	await fetch('DELETE', `/articles/${articleID}`, null, { type });
	dispatch({
		articleID,
		removeType: type,
		type: 'DELETE_ARTICLE',
	});
};

export const clearArticles = (dispatch) => {
	return dispatch({ type: 'CLEAR_ARTICLES' });
};
