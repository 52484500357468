import fetch from '../utils/fetch';
import isDispatch from '../utils/isDispatch';

export const getUsers = (query) => {
	return fetch('GET', '/users', null, query);
};

export const getUser = async (dispatch, userID) => {
	const res = await fetch('GET', `/users/${userID}`);
	dispatch({
		type: 'UPDATE_USER',
		user: res.data,
	});
	localStorage.setItem('authedUser', JSON.stringify(res.data));
};

export const updateUser = async (dispatch, userID, data) => {
	let p1 = userID,
		p2 = data;
	if (!isDispatch(dispatch)) {
		p1 = dispatch;
		p2 = userID;
	}
	const res = await fetch('PUT', `/users/${p1}`, p2);
	if (isDispatch(dispatch)) {
		dispatch({
			type: 'UPDATE_USER',
			user: res.data,
		});
		localStorage.setItem('authedUser', JSON.stringify(res.data));
	} else {
		return res;
	}
};

export const deleteUser = (userID, password) => {
	return fetch('DELETE', `/users/${userID}`, { password });
};

export const deleteHistory = (userID, services, password) => {
	return fetch('DELETE', `/users/history/${userID}`, { services, password });
};
