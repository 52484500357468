import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import PageTitle from '../../components/PageTitle';
import { getBlocklist, updateBlocklist } from '../../api/blocklist';

const Blocklist = () => {
	const { t } = useTranslation();
	const [submitting, setSubmitting] = useState(false);
	const { register, handleSubmit, errors, reset } = useForm();

	useEffect(() => {
		const fetchData = async () => {
			const res = await getBlocklist();
			reset({
				feedURLs: res.data.feedURLs ? res.data.feedURLs.join('\n') : '',
				unsafeURLs: res.data.unsafeURLs ? res.data.unsafeURLs.join('\n') : '',
				imageURLs: res.data.imageURLs ? res.data.imageURLs.join('\n') : '',
			});
		};

		fetchData();
	}, [reset]);

	const onSubmit = async (data) => {
		try {
			toast.dismiss();
			setSubmitting(true);
			const obj = {
				feedURLs: data.feedURLs
					? data.feedURLs
							.split('\n')
							.filter((f) => f)
							.map((f) => f.trim())
					: [],
				unsafeURLs: data.unsafeURLs
					? data.unsafeURLs
							.split('\n')
							.filter((f) => f)
							.map((f) => f.trim())
					: [],
				imageURLs: data.imageURLs
					? data.imageURLs
							.split('\n')
							.filter((f) => f)
							.map((f) => f.trim())
					: [],
			};
			await updateBlocklist(obj);
			setSubmitting(false);
			toast.success(t('The blacklist has been updated.'));
		} catch (err) {
			setSubmitting(false);
		}
	};

	return (
		<>
			<PageTitle title={t('Blocklist')} />
			<h1>{t('Blocklist')}</h1>
			<form className="settings-form" onSubmit={handleSubmit(onSubmit)}>
				<div className={classNames('form-group', { error: errors.feedURLs })}>
					<label>{t('Feed URL')}</label>
					<textarea rows={10} name="feedURLs" ref={register} />
					<div className="note">
						{t('Feed URL not allowed to be added, multiple newlines.')}
					</div>
				</div>
				<div className={classNames('form-group', { error: errors.unsafeURLs })}>
					<label>{t('Unsafe URL')}</label>
					<textarea rows={10} name="unsafeURLs" ref={register} />
					<div className="note">{t('Unsafe URL, multiple newlines.')}</div>
				</div>
				<div className={classNames('form-group', { error: errors.imageURLs })}>
					<label>{t('Image URL')}</label>
					<textarea rows={10} name="imageURLs" ref={register} />
					<div className="note">
						{t('Image URL not allowed to be downloaded, multiple newlines.')}
					</div>
				</div>
				<div className="form-group">
					<button className="btn primary" disabled={submitting} type="submit">
						{t('Save')}
					</button>
				</div>
			</form>
		</>
	);
};

export default Blocklist;
