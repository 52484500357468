export const languageOptions = [
	{
		value: 'en',
		label: 'English',
	},
	{
		value: 'zh-cn',
		label: '简体中文',
	},
];

export const feedTypeOptions = [
	{ value: 'rss', label: 'RSS' },
	{ value: 'podcast', label: 'Podcast' },
];

export const feedStatusOptions = [
	{
		value: 'active',
		label: 'Active',
	},
	{
		value: 'inactive',
		label: 'Inactive',
	},
	{
		value: 'failure',
		label: 'Failure',
	},
];

export const scrapeIntervalOptions = [
	{ value: 0, label: 'default' },
	{ value: 20, label: '20 minutes' },
	{ value: 30, label: '30 minutes' },
	{ value: 40, label: '40 minutes' },
	{ value: 50, label: '50 minutes' },
	{ value: 60, label: '60 minutes' },
];

export const billingTypeOptions = [
	{ value: 'day', label: 'day' },
	{ value: 'month', label: 'month' },
	{ value: 'year', label: 'year' },
];
