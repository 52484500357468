import fetch from '../utils/fetch';
import isDispatch from '../utils/isDispatch';

export const getFeatured = async (dispatch, params, cancelToken) => {
	if (!isDispatch(dispatch)) {
		params = dispatch;
	}
	const res = await fetch('GET', '/featured', null, params, null, cancelToken);
	if (isDispatch(dispatch)) {
		dispatch({
			feeds: res.data,
			type: 'BATCH_UPDATE_FEEDS',
		});
	} else {
		return res;
	}
};

export const getFeeds = (params) => {
	return fetch('GET', '/feeds', null, params);
};

export const getFeed = (feedID) => {
	return fetch('GET', `/feeds/${feedID}`);
};

export const addFeed = (feedURL) => {
	return fetch('POST', '/feeds', { feedURL });
};

export const updateFeed = (feedID, data) => {
	return fetch('PUT', `/feeds/${feedID}`, data);
};

export const deleteFeed = (feedID) => {
	return fetch('DELETE', `/feeds/${feedID}`);
};

export const mergeFeed = (lFeedID, rFeedID) => {
	return fetch('PUT', '/feeds/merge', { lFeedID, rFeedID });
};
