import React from 'react';
import ReactLightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/dist/plugins/zoom';

// import { ReactComponent as DownloadIcon } from '../images/icons/tray-arrow-down.svg';
// import { ReactComponent as ShareIcon } from '../images/icons/share-variant-outline.svg';
import { ReactComponent as CloseIcon } from '../images/icons/close.svg';

const Lightbox = ({ isOpen = false, attribs = {}, closeModal }) => {
	// const downloadImage = (imageURL) => {
	// 	var element = document.createElement("a");
	// 	element.href = imageURL;
	// 	element.download = true;
	// 	element.click();
	// }

	// const shareImage = async (imageURL) => {
	// 	try {
	// 		if (navigator.share) {
	// 			const response = await fetch(imageURL);
	// 			const blob = await response.blob();
	// 			const filesArray = [
	// 				new File(
	// 					[blob],
	// 					'meme.jpg',
	// 					{
	// 						type: blob.type,
	// 						lastModified: new Date().getTime()
	// 					}
	// 				)
	// 			];
	// 			const shareData = {
	// 				files: filesArray,
	// 			};
	// 			navigator.share(shareData);
	// 		}
	// 	} catch (err) {
	// 		// XXX
	// 	}
	// };

	return (
		<ReactLightbox
			open={isOpen}
			plugins={[Zoom]}
			close={closeModal}
			zoom={{ maxZoomPixelRatio: 2 }}
			slides={[{ src: attribs.src, alt: attribs.alt }]}
			carousel={{ finite: true }}
			controller={{ closeOnBackdropClick: true }}
			render={{
				buttonPrev: () => null,
				buttonNext: () => null,
				buttonZoomIn: () => null,
				buttonZoomOut: () => null,
				iconClose: () => <CloseIcon className="yarl__icon" />,
			}}
			// toolbar={{
			// 	buttons: [
			// 		<button
			// 			key="download"
			// 			type="button"
			// 			aria-label="Download"
			// 			className="yarl__button"
			// 			onClick={() => downloadImage(attribs.src)}
			// 		>
			// 			<DownloadIcon className="yarl__icon" />
			// 		</button>,
			// 		<button
			// 			key="share"
			// 			type="button"
			// 			aria-label="Share"
			// 			className="yarl__button"
			// 			onClick={() => shareImage(attribs.src)}
			// 		>
			// 			<ShareIcon className="yarl__icon" />
			// 		</button>,
			// 		'close',
			// 	],
			// }}
		/>
	);
};

export default Lightbox;
