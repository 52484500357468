import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { updateInterest } from '../../../api/interest';

import { ReactComponent as ExitIcon } from '../../../images/icons/close.svg';

const EditModal = ({ isOpen = false, interest = {}, closeModal, onEnd }) => {
	const { t } = useTranslation();
	const [submitting, setSubmitting] = useState(false);
	const { register, handleSubmit, errors, reset } = useForm();

	useEffect(() => {
		reset({
			name: interest.name,
			subtitle: interest.subtitle,
			image: interest.image,
		});
	}, [interest, reset]);

	const onClose = () => {
		toast.dismiss();
		setSubmitting(false);
		closeModal();
	};

	const onSubmit = async (data) => {
		try {
			toast.dismiss();
			setSubmitting(true);
			await updateInterest(interest._id, data);
			onClose();
			onEnd && onEnd();
		} catch (err) {
			setSubmitting(false);
		}
	};

	return (
		isOpen && (
			<ReactModal
				className="modal"
				isOpen={true}
				ariaHideApp={false}
				onRequestClose={onClose}
				overlayClassName="modal-overlay"
				shouldCloseOnOverlayClick={true}
			>
				<header>
					<h1>{t('Edit {{interestName}} interest', { interestName: interest.name })}</h1>
					<span className="exit" onClick={onClose}>
						<ExitIcon />
					</span>
				</header>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={classNames('form-group', 'required', { error: errors.name })}>
						<label>{t('Name')}</label>
						<input type="text" name="name" ref={register({ required: true })} />
					</div>
					<div className={classNames('form-group', { error: errors.subtitle })}>
						<label>{t('Description')}</label>
						<textarea
							rows="3"
							maxLength="280"
							placeholder={t('280 characters or less')}
							name="subtitle"
							ref={register({
								maxLength: 280,
							})}
						/>
					</div>
					<div className={classNames('form-group', { error: errors.url })}>
						<label>{t('Image URL')}</label>
						<input type="url" name="image" ref={register} />
					</div>
					<div className="buttons">
						<button type="submit" className="btn primary" disabled={submitting}>
							{t('Save')}
						</button>
						<button type="button" className="btn link cancel" onClick={onClose}>
							{t('Cancel')}
						</button>
					</div>
				</form>
			</ReactModal>
		)
	);
};

export default EditModal;
