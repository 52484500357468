import fetch from '../utils/fetch';

export const getPlans = () => {
	return fetch('GET', '/subscriptions/plans');
};

export const cancelSubscription = (subscriptionID) => {
	return fetch('POST', '/subscriptions/cancel', { subscriptionID });
};

export const txnSubscription = (checkoutID) => {
	return fetch('POST', `/subscriptions/transaction`, { checkoutID });
};

export const updateSubscription = (planID) => {
	return fetch('POST', `/subscriptions/${planID}`);
};

export const getSubscription = (subscriptionID) => {
	return fetch('GET', `/subscriptions/${subscriptionID}`);
};
