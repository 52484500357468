import fetch from '../utils/fetch';

export const getFollowFeeds = (params) => {
	return fetch('GET', '/follows', null, params);
};

export const followFeed = async (dispatch, feedID, folderID) => {
	const res = await fetch('POST', '/follows', { feedID, folderID });
	dispatch({
		follow: res.data,
		type: 'UPDATE_FOLLOW_FEED',
	});
};

export const unfollowFeed = async (dispatch, feedIDs) => {
	await fetch('DELETE', '/follows', { feedIDs });
	dispatch({
		feedIDs,
		type: `UPDATE_UNFOLLOW_FEED`,
	});
};

export const primaryFeed = async (dispatch, feedID, primary) => {
	const res = await fetch('PUT', '/follows', { feedID, primary });
	dispatch({
		follow: res.data,
		type: 'UPDATE_FOLLOW_FEED',
	});
};

export const renameFeed = async (dispatch, feedID, alias) => {
	const res = await fetch('PUT', '/follows', { feedID, alias });
	dispatch({
		follow: res.data,
		type: `UPDATE_FOLLOW_FEED`,
	});
};

export const fullTextFeed = async (dispatch, feedID, fullText) => {
	const res = await fetch('PUT', '/follows', { feedID, fullText });
	dispatch({
		follow: res.data,
		type: `UPDATE_FOLLOW_FEED`,
	});
};

export const followFolder = async (dispatch, feedIDs, folderID) => {
	const res = await fetch('PUT', `/follows/folder`, { feedIDs, folderID });
	dispatch({
		feedIDs,
		folderID,
		type: `UPDATE_FOLLOW_FOLDER`,
	});
	return res;
};
