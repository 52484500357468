import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { updateFeed } from '../../../api/feed';
import { feedTypeOptions, scrapeIntervalOptions } from '../../../utils/options';
import Select from '../../../components/Select';
import CategorySelect from '../../../components/CategorySelect';
import InterestSelect from '../../../components/InterestSelect';

import { ReactComponent as ExitIcon } from '../../../images/icons/close.svg';

const UpdateModal = ({ isOpen = false, feed = {}, closeModal, onEnd }) => {
	const { t } = useTranslation();
	const [category, setCategory] = useState();
	const [interests, setInterests] = useState();
	const [submitting, setSubmitting] = useState(false);
	const { control, register, handleSubmit, reset, errors } = useForm();

	useEffect(() => {
		if (feed.category) {
			setCategory({
				value: feed.category._id,
				label: feed.category.name,
			});
		} else {
			setCategory();
		}
		if (feed.interests) {
			setInterests(
				feed.interests.map((f) => ({
					value: f._id,
					label: f.name,
				})),
			);
		} else {
			setInterests();
		}
		reset({
			title: feed.title,
			type: feed.type,
			feedURL: feed.feedURL,
			feedURLs: feed.feedURLs ? feed.feedURLs.join('\n') : '',
			canonicalURL: feed.canonicalURL,
			featuredURL: feed.images ? feed.images.featured : '',
			bannerURL: feed.images ? feed.images.banner : '',
			faviconURL: feed.images ? feed.images.favicon : '',
			iconURL: feed.images ? feed.images.icon : '',
			ogURL: feed.images ? feed.images.og : '',
			scrapeInterval: parseInt(feed.scrapeInterval || 0),
		});
	}, [feed, reset]);

	const onClose = () => {
		toast.dismiss();
		setSubmitting(false);
		closeModal();
	};

	const onSubmit = async (data) => {
		try {
			toast.dismiss();
			setSubmitting(true);
			const obj = {
				title: data.title,
				type: data.type,
				feedURL: data.feedURL,
				feedURLs: data.feedURLs
					? data.feedURLs
							.split('\n')
							.filter((f) => f)
							.map((f) => f.trim())
					: [],
				canonicalURL: data.canonicalURL,
				category: category ? category.value : null,
				interests: interests ? interests.map((interest) => interest.value) : [],
				images: {
					featured: data.featuredURL || '',
					banner: data.bannerURL || '',
					favicon: data.faviconURL || '',
					icon: data.iconURL || '',
					og: data.ogURL || '',
				},
				scrapeInterval: parseInt(data.scrapeInterval),
			};
			await updateFeed(feed._id, obj);
			onEnd && onEnd();
			onClose();
		} catch (err) {
			setSubmitting(false);
		}
	};

	return (
		isOpen && (
			<ReactModal
				className="modal"
				isOpen={true}
				ariaHideApp={false}
				onRequestClose={onClose}
				overlayClassName="modal-overlay"
				shouldCloseOnOverlayClick={true}
			>
				<header>
					<h1>{t('Edit feed')}</h1>
					<span className="exit" onClick={onClose}>
						<ExitIcon />
					</span>
				</header>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={classNames('form-group', 'required', { error: errors.title })}>
						<label>{t('Title')}</label>
						<input
							type="text"
							autoComplete="false"
							name="title"
							ref={register({ required: true })}
						/>
					</div>
					<div
						className={classNames('form-group', 'required', {
							error: errors.scrapeInterval,
						})}
					>
						<label>
							{t('Crawl interval')}
							<span className="note">({t('minute')})</span>
						</label>
						<Select
							control={control}
							name="scrapeInterval"
							rules={{ required: true }}
							options={scrapeIntervalOptions.map(({ value, label }) => ({
								value,
								label: t(label),
							}))}
						/>
					</div>
					<div className={classNames('form-group', 'required', { error: errors.type })}>
						<label>{t('Type')}</label>
						<Select
							control={control}
							name="type"
							rules={{ required: true }}
							options={feedTypeOptions.map(({ value, label }) => ({
								value,
								label: t(label),
							}))}
						/>
					</div>
					<div className="form-group">
						<label>{t('Category')}</label>
						<CategorySelect value={category} onChange={setCategory} />
					</div>
					<div className="form-group">
						<label>{t('Interests')}</label>
						<InterestSelect isMulti value={interests} onChange={setInterests} />
					</div>
					<div
						className={classNames('form-group', 'required', { error: errors.feedURL })}
					>
						<label>{t('Feed URL')}</label>
						<input
							type="url"
							autoComplete="false"
							name="feedURL"
							disabled={feed.duplicateOf}
							ref={register({ required: true })}
						/>
					</div>
					<div className={classNames('form-group', { error: errors.feedURLs })}>
						<label>{t('Feed URLs')}</label>
						<textarea rows="3" name="feedURLs" ref={register} />
						<div className="note">{t('Feed URLs, multiple newlines.')}</div>
					</div>
					<div className={classNames('form-group', { error: errors.canonicalURL })}>
						<label>{t('Canonical URL')}</label>
						<input type="url" autoComplete="false" name="canonicalURL" ref={register} />
					</div>
					<div className={classNames('form-group', { error: errors.featuredURL })}>
						<label>{t('Featured URL')}</label>
						<input type="url" autoComplete="false" name="featuredURL" ref={register} />
					</div>
					<div className={classNames('form-group', { error: errors.bannerURL })}>
						<label>{t('Banner URL')}</label>
						<input type="url" autoComplete="false" name="bannerURL" ref={register} />
					</div>
					<div className={classNames('form-group', { error: errors.faviconURL })}>
						<label>{t('Favicon URL')}</label>
						<input type="url" autoComplete="false" name="faviconURL" ref={register} />
					</div>
					<div className={classNames('form-group', { error: errors.iconURL })}>
						<label>{t('Icon URL')}</label>
						<input type="url" autoComplete="false" name="iconURL" ref={register} />
					</div>
					<div className={classNames('form-group', { error: errors.ogURL })}>
						<label>{t('OG URL')}</label>
						<input type="url" autoComplete="false" name="ogURL" ref={register} />
					</div>
					<div className="buttons">
						<button type="submit" className="btn primary" disabled={submitting}>
							{t('Save')}
						</button>
						<button type="button" className="btn link cancel" onClick={onClose}>
							{t('Cancel')}
						</button>
					</div>
				</form>
			</ReactModal>
		)
	);
};

export default UpdateModal;
