import fetch from '../utils/fetch';

export const getTags = (params) => {
	return fetch('GET', '/tags', null, params);
};

export const addTag = (name) => {
	return fetch('POST', '/tags', { name });
};

export const renameTag = (tagID, name) => {
	return fetch('PUT', `/tags/${tagID}`, { name });
};

export const deleteTag = async (dispatch, tagID, articleID) => {
	const res = await fetch('DELETE', `/tags/${tagID}`, { articleID });
	if (articleID) {
		dispatch({
			articleID,
			star: res.data,
			type: 'UPDATE_ARTICLE_STARS',
		});
	}
};
