// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
	UPDATE_USER: (previousState, action) => {
		return {
			...previousState,
			user: { ...action.user },
		};
	},
	UPDATE_MENU_OPEN: (previousState, action) => {
		return {
			...previousState,
			menuIsOpen: action.menuIsOpen,
		};
	},
	UPDATE_FOLDER_OPEN: (previousState, action) => {
		return {
			...previousState,
			folderIsOpen: action.folderIsOpen,
		};
	},
	BATCH_UPDATE_TOTALS: (previousState, action) => {
		return {
			...previousState,
			totals: { ...action.totals },
		};
	},
	BATCH_UPDATE_CATEGORIES: (previousState, action) => {
		return {
			...previousState,
			categories: [...action.categories],
		};
	},
	BATCH_UPDATE_INTERESTS: (previousState, action) => {
		return {
			...previousState,
			interests: [...action.interests],
		};
	},
	BATCH_COLLECTIONS: (previousState, action) => {
		let folders = {},
			follows = {};

		for (const item of action.collections) {
			if (item._id !== '0') {
				folders[item._id] = { _id: item._id, name: item.name, icon: item.icon };
			}

			for (const feed of item.follows) {
				follows[feed._id] = feed;
			}
		}

		return { ...previousState, folders, follows };
	},
	NEW_FOLDER: (previousState, action) => {
		const folders = previousState.folders;
		const folder = action.folder;

		return {
			...previousState,
			folders: {
				...folders,
				[folder._id]: {
					_id: folder._id,
					name: folder.name,
					icon: folder.icon,
				},
			},
		};
	},
	UPDATE_FOLDER: (previousState, action) => {
		const folders = previousState.folders;
		const folder = action.folder;

		return {
			...previousState,
			folders: {
				...folders,
				[folder._id]: {
					_id: folder._id,
					name: folder.name,
					icon: folder.icon,
				},
			},
		};
	},
	DELETE_FOLDER: (previousState, action) => {
		let folders = { ...previousState.folders };
		let follows = { ...previousState.follows };
		const feedIDs = action.feedIDs || [];
		const folderIDs = action.folderIDs || [];
		const unfollow = action.unfollow;
		const followValues = Object.values(follows || {});

		for (let i = 0; i < folderIDs.length; i++) {
			const folderID = folderIDs[i];
			if (folders[folderID]) {
				delete folders[folderID];
			}
		}

		const folderFeeds = followValues.filter((f) => folderIDs.includes(f.folder));

		if (unfollow) {
			let feeds = folderFeeds;
			if (feedIDs && feedIDs.length > 0) {
				feeds = folderFeeds.filter((f) => feedIDs.includes(f._id));
			}
			for (let feed of feeds) {
				delete follows[feed._id];
			}
		}

		for (let feed of folderFeeds) {
			if (follows[feed._id]) {
				follows[feed._id].folder = null;
				follows[feed._id].checked = false;
			}
		}

		return { ...previousState, folders, follows };
	},
	BATCH_OPML_FEEDS: (previousState, action) => {
		const follows = previousState.follows;
		const folders = previousState.folders;

		const opmlFeeds = action.follows.reduce(
			(result, { feed, alias, folder, primary }) => {
				result[feed._id] = {
					...feed,
					title: !alias ? feed.title : alias,
					folder,
					primary,
				};
				return result;
			},
			{},
		);

		const opmlFolders = action.folders.reduce((result, { _id, name }) => {
			result[_id] = { _id, name };
			return result;
		}, {});

		return {
			...previousState,
			folders: {
				...folders,
				...opmlFolders,
			},
			follows: {
				...follows,
				...opmlFeeds,
			},
		};
	},
	UPDATE_FOLLOW_FEED: (previousState, action) => {
		const data = action.follow;
		const follows = previousState.follows;

		let original = data.feed.duplicateOf && follows && follows[data.feed.duplicateOf];

		return {
			...previousState,
			follows: {
				...follows,
				[data.feed._id]: original || {
					...data.feed,
					title: !data.alias ? data.feed.title : data.alias,
					folder: data.folder,
					primary: data.primary,
					fullText: data.fullText,
				},
			},
		};
	},
	UPDATE_UNFOLLOW_FEED: (previousState, action) => {
		const feedIDs = action.feedIDs;
		const follows = { ...previousState.follows };

		for (let i = 0; i < feedIDs.length; i++) {
			const feedID = feedIDs[i];
			if (follows[feedID]) {
				delete follows[feedID];
			}
		}

		return {
			...previousState,
			follows,
		};
	},
	UPDATE_FOLLOW_FOLDER: (previousState, action) => {
		const follows = { ...previousState.follows };
		const feedIDs = action.feedIDs;
		const folderID = action.folderID;

		for (let i = 0; i < feedIDs.length; i++) {
			const feedID = feedIDs[i];
			if (follows[feedID]) {
				follows[feedID].folder = folderID || null;
				follows[feedID].checked = false;
			}
		}

		return {
			...previousState,
			follows,
		};
	},
	BATCH_UPDATE_ARTICLES: (previousState, action) => {
		let articles = action.articles.reduce((result, item) => {
			result[item._id] = item;
			return result;
		}, {});

		// TODO: Refactor
		for (let article in articles) {
			if (!article.duplicateOf) continue;
			const previous =
				previousState.articles && previousState.articles[article.duplicateOf];
			const next = articles[article.duplicateOf];
			articles[article._id] = next || previous || article;
		}

		// Remove duplicates
		const articlesOriginal = {
			...previousState.articles,
			...articles,
		};
		const articlesState = {},
			articlesArray = [];

		for (const articleID in articlesOriginal) {
			const article = articlesOriginal[articleID];
			if (!articlesArray.includes(article.contentHash)) {
				articlesArray.push(article.contentHash);
				articlesState[article._id] = article;
			}
		}

		return {
			...previousState,
			reachedEndOfArticles: action.articles.length === 0,
			articles: articlesState,
		};
	},
	CLEAR_ARTICLES: (previousState, action) => {
		return {
			...previousState,
			reachedEndOfArticles: false,
			articles: {},
		};
	},
	CLEAR_UNREAD_ARTICLES: (previousState, action) => {
		const user = previousState.user;
		const articles = { ...previousState.articles };
		const follows = { ...previousState.follows };
		const feedIDs = action.feedIDs ? [...action.feedIDs] : [];
		const folderIDs = action.folderIDs || [];

		if (user.settings.unreadOnly) {
			const feeds = Object.values(follows).filter((follow) =>
				folderIDs.includes(follow.folder),
			);

			const uniqueFeedIDs = new Set(feedIDs);
			feeds.forEach((feed) => {
				uniqueFeedIDs.add(feed._id);
			});

			const mergedFeedIDs = [...uniqueFeedIDs];

			for (const articleID of Object.keys(articles)) {
				const article = articles[articleID];
				if (mergedFeedIDs.includes(article.feed._id)) {
					delete articles[articleID];
				}
			}
		}

		return {
			...previousState,
			reachedEndOfArticles:
				Object.keys(articles).length === 0 ? true : previousState.reachedEndOfArticles,
			articles,
		};
	},
	DELETE_ARTICLE: (previousState, action) => {
		const articleID = action.articleID;
		const removeType = action.removeType;
		const articles = { ...previousState.articles };
		delete articles[articleID];

		let totals = { ...previousState.totals };
		let article = previousState.article ? { ...previousState.article } : undefined;

		if (removeType === 'stars') {
			totals.star = totals.star >= 1 ? totals.star - 1 : 0;
			if (article && article._id === articleID) {
				article.stared = false;
			}
		}

		if (removeType === 'recent-read') {
			totals.recentRead = totals.recentRead >= 1 ? totals.recentRead - 1 : 0;
		}

		if (removeType === 'recent-played') {
			totals.recentPlayed = totals.recentPlayed >= 1 ? totals.recentPlayed - 1 : 0;
		}

		return {
			...previousState,
			articles,
			article,
			totals,
		};
	},
	CLEAR_ARTICLE_CONTENT: (previousState, action) => {
		return {
			...previousState,
			article: undefined,
		};
	},
	UPDATE_ARTICLE_CONTENT: (previousState, action) => {
		const article = action.article;
		const articles = { ...previousState.articles };
		const totals = { ...previousState.totals };

		if (articles[article._id] && articles[article._id].unread) {
			articles[article._id].unread = false;
			totals.recentRead = totals.recentRead + 1;
		}

		return {
			...previousState,
			totals,
			article,
			articles,
		};
	},
	STAR_ARTICLE: (previousState, action) => {
		let article = { ...previousState.article };
		if (article._id === action.articleID) {
			article.stared = true;
		}

		let totals = { ...previousState.totals };
		totals.star = totals.star >= 0 ? totals.star + 1 : 0;

		return {
			...previousState,
			article,
			totals,
		};
	},
	UNSTAR_ARTICLE: (previousState, action) => {
		let article = { ...previousState.article };
		if (article._id === action.articleID) {
			article.stared = false;
			article.stars = [];
		}

		let totals = { ...previousState.totals };
		totals.star = totals.star > 0 ? totals.star - 1 : 0;

		return {
			...previousState,
			article,
			totals,
		};
	},
	UPDATE_ARTICLE_STARS: (previousState, action) => {
		const article = { ...previousState.article };
		if (article._id === action.articleID) {
			if (action.star && !article.stars.find((t) => t._id === action.star.tags._id)) {
				article.stars = action.star.tags;
			}
			if (!action.star) {
				article.stared = false;
			}
		}
		return {
			...previousState,
			article,
		};
	},
	PLAY_EPISODE: (previousState, action) => {
		const player = { ...action.article, playing: true, open: true };
		delete player.description;
		delete player.content;

		let totals = { ...previousState.totals };
		let articles = { ...previousState.articles };
		if (articles[player._id] && !articles[player._id].played) {
			articles[player._id].played = true;
			totals.recentPlayed = totals.recentPlayed + 1;
		}

		return { ...previousState, totals, articles, player };
	},
	PAUSE_EPISODE: (previousState, action) => {
		return {
			...previousState,
			player: { ...previousState.player, playing: false },
		};
	},
	RESUME_EPISODE: (previousState, action) => {
		return {
			...previousState,
			player: { ...previousState.player, playing: true },
		};
	},
	CLOSE_PLAYER: (previousState, action) => {
		let existingState = { ...previousState };
		delete existingState.player;
		return { ...existingState };
	},
	BATCH_UPDATE_FEEDS: (previousState, action) => {
		const existingFeeds = previousState.feeds || [];
		return {
			...previousState,
			reachedEndOfFeeds: action.feeds.length < 20,
			feeds: [...existingFeeds, ...action.feeds],
		};
	},
	CLEAR_FEEDS: (previousState, action) => {
		return {
			...previousState,
			reachedEndOfFeeds: false,
			feeds: [],
		};
	},
	UPDATE_FOLDER_CHECKED: (previousState, action) => {
		let folders = { ...previousState.folders };
		let follows = { ...previousState.follows };
		const folderID = action.folderID;
		const followValues = Object.values(follows || {});

		if (folders[folderID].checked) {
			folders[folderID].checked = false;
		} else {
			folders[folderID].checked = true;
		}

		const folderFeeds = followValues.filter((f) => f.folder === folderID);
		for (let feed of folderFeeds) {
			if (folders[folderID].checked && !follows[feed._id].checked) {
				follows[feed._id].checked = true;
			} else {
				follows[feed._id].checked = false;
			}
		}

		return {
			...previousState,
			folders,
			follows,
		};
	},
	UPDATE_FEED_CHECKED: (previousState, action) => {
		let folders = { ...previousState.folders };
		let follows = { ...previousState.follows };
		const folderID = action.folderID;
		const feedID = action.feedID;

		if (follows[feedID].checked) {
			follows[feedID].checked = false;
		} else {
			follows[feedID].checked = true;
		}

		if (folderID) {
			const followValues = Object.values(follows || {});
			const checkedFeeds = followValues.filter((f) => f.folder === folderID && f.checked);
			if (checkedFeeds.length > 0) {
				folders[folderID].checked = true;
			} else {
				folders[folderID].checked = false;
			}
		}

		return {
			...previousState,
			folders,
			follows,
		};
	},
	UPDATE_COLLECTIONS_CHECKED: (previousState, action) => {
		let folders = { ...previousState.folders };
		let follows = { ...previousState.follows };
		const checkedAll = action.checkedAll;

		if (checkedAll) {
			for (const folderID in folders) {
				if (!folders[folderID].checked) {
					folders[folderID].checked = true;
				}
			}
			for (const feedID in follows) {
				if (!follows[feedID].checked) {
					follows[feedID].checked = true;
				}
			}
		} else {
			for (const folderID in folders) {
				if (folders[folderID].checked) {
					folders[folderID].checked = false;
				}
			}
			for (const feedID in follows) {
				if (follows[feedID].checked) {
					follows[feedID].checked = false;
				}
			}
		}

		return {
			...previousState,
			folders,
			follows,
		};
	},
};

// ------------------------------------
// Reducer
// ------------------------------------
const reducer = (previousState = {}, action) => {
	const handler = ACTION_HANDLERS[action.type];

	return handler ? handler(previousState, action) : previousState;
};

export default reducer;
