import fetch from '../utils/fetch';

export const getFolders = (params) => {
	return fetch('GET', '/folders', null, params);
};

export const newFolder = async (dispatch, data) => {
	const res = await fetch('POST', '/folders', data);
	dispatch({
		folder: res.data,
		type: 'NEW_FOLDER',
	});
	return res;
};

export const updateFolder = async (dispatch, folderID, data) => {
	const res = await fetch('PUT', `/folders/${folderID}`, data);
	dispatch({
		folder: res.data,
		type: 'UPDATE_FOLDER',
	});
};

export const deleteFolder = async (dispatch, unfollow, folderIDs, feedIDs) => {
	await fetch('DELETE', `/folders`, { unfollow, folderIDs, feedIDs });
	dispatch({
		unfollow,
		folderIDs,
		feedIDs,
		type: 'DELETE_FOLDER',
	});
};
