import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ArticleList from './ArticleList';
import StarArticleList from './StarArticleList';
import ReadArticleList from './ReadArticleList';
import PlayedArticleList from './PlayedArticleList';
import ArticlePreview from './ArticlePreview';

const ArticlePanel = () => {
	return (
		<>
			<div className="article-list">
				<Switch>
					<Route component={PlayedArticleList} path="/recent-played/article/:articleID" />
					<Route component={PlayedArticleList} path="/recent-played" />
					<Route component={ReadArticleList} path="/recent-read/article/:articleID" />
					<Route component={ReadArticleList} path="/recent-read" />
					<Route component={StarArticleList} path="/stars/article/:articleID" />
					<Route component={StarArticleList} path="/stars" />
					<Route
						component={ArticleList}
						path="/folder/:folderID/feed/:feedID/article/:articleID"
					/>
					<Route component={ArticleList} path="/folder/:folderID/feed/:feedID" />
					<Route component={ArticleList} path="/folder/:folderID/article/:articleID" />
					<Route component={ArticleList} path="/folder/:folderID" />
					<Route component={ArticleList} path="/feed/:feedID/article/:articleID" />
					<Route component={ArticleList} path="/feed/:feedID" />
					<Route component={ArticleList} path="/article/:articleID" />
					<Route component={ArticleList} path="/" />
				</Switch>
			</div>
			<ArticlePreview />
		</>
	);
};

export default ArticlePanel;
