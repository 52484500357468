import fetch from '../utils/fetch';

export const starArticle = async (dispatch, articleID) => {
	await fetch('POST', '/stars', { articleID });
	dispatch({
		articleID,
		type: 'STAR_ARTICLE',
	});
};

export const unstarArticle = async (dispatch, articleID) => {
	await fetch('DELETE', `/stars/${articleID}`);
	dispatch({
		articleID,
		type: 'UNSTAR_ARTICLE',
	});
};

export const updateStar = async (dispatch, articleID, tagID) => {
	const res = await fetch('PUT', `/stars/${articleID}`, { tagID });
	dispatch({
		articleID,
		star: res.data,
		type: 'UPDATE_ARTICLE_STARS',
	});
};
