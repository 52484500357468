import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import classNames from 'classnames';

import PageTitle from '../components/PageTitle';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ArticlePanel from '../components/Feeds/ArticlePanel';
import FeedList from '../components/Feeds/FeedList';
import FeedDetail from '../components/Feeds/FeedDetail';
import useFontSize from '../hooks/useFontSize';
import useWindowScroll from '../hooks/useWindowScroll';
import useSubscriptionExpired from '../hooks/useSubscriptionExpired';

const Dashboard = () => {
	useFontSize();
	useWindowScroll();
	useSubscriptionExpired();

	const user = useSelector((state) => state.user || {});
	const menuIsOpen = useSelector((state) =>
		state.menuIsOpen === undefined || state.menuIsOpen === null ? true : state.menuIsOpen,
	);
	const mobileHideSidebar = (user.settings || {}).mobileHideSidebar;

	return (
		<>
			<Header />
			<div
				className={classNames('main', {
					hide: mobileHideSidebar,
					toggler: !menuIsOpen,
				})}
			>
				<PageTitle />
				<Sidebar />
				<Switch>
					<Route component={FeedDetail} path="/library/:libraryID" />
					<Route component={FeedList} path="/library" />
					<Route
						component={ArticlePanel}
						path="/folder/:folderID/feed/:feedID/article/:articleID"
					/>
					<Route component={ArticlePanel} path="/folder/:folderID/feed/:feedID" />
					<Route component={ArticlePanel} path="/folder/:folderID/article/:articleID" />
					<Route component={ArticlePanel} path="/folder/:folderID" />
					<Route component={ArticlePanel} path="/feed/:feedID/article/:articleID" />
					<Route component={ArticlePanel} path="/feed/:feedID" />
					<Route component={ArticlePanel} path="/recent-played/article/:articleID" />
					<Route component={ArticlePanel} path="/recent-played" />
					<Route component={ArticlePanel} path="/recent-read/article/:articleID" />
					<Route component={ArticlePanel} path="/recent-read" />
					<Route component={ArticlePanel} path="/stars/article/:articleID" />
					<Route component={ArticlePanel} path="/stars" />
					<Route component={ArticlePanel} path="/article/:articleID" />
					<Route component={ArticlePanel} path="/" />
				</Switch>
			</div>
		</>
	);
};

export default Dashboard;
