import React from 'react';

import Image from '../Image';

const Avatar = ({ width = 28, height = 28, title, avatarURL, children, onClick }) => {
	return (
		<div className="avatar" onClick={onClick}>
			<Image alt={title} width={width} height={height} src={avatarURL} />
			{children}
		</div>
	);
};

export default Avatar;
