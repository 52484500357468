import React, { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Loader from '../../../components/Loader';
import Image from '../../../components/Image';
import PageTitle from '../../../components/PageTitle';
import SearchInput from '../../../components/SearchInput';
import MoreIcon from '../../../components/MoreIcon';
import Paging from '../../../components/Paging';
import AddModal from './AddModal';
import FilterPopover from './FilterPopover';
import ActionPopover from './ActionPopover';

import { getInterests } from '../../../api/interest';

const Interests = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [interest, setInterest] = useState();
	const [interests, setInterests] = useState([]);
	const [popover, setPopover] = useState({});
	const [filters, setFilters] = useState({
		page: 1,
		per_page: 10,
		sort_by: 'createdAt,-1',
	});

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const res = await getInterests(filters);
			setInterests(res.data);
			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	}, [filters]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const openPopover = (anchorRef, skipClick, interest) => {
		setInterest(interest);
		setPopover({
			anchorRef,
			skipClick,
			id: popover.id === interest._id ? '' : interest._id,
			isOpen: !popover.isOpen,
		});
	};

	const closePopover = () => {
		setPopover({});
	};

	return (
		<>
			<PageTitle title={t('Manage interests')} />
			<h1>{t('Manage interests')}</h1>
			<div className="filters">
				<div className="box">
					<SearchInput
						type="text"
						onChange={(value) => {
							setFilters({ ...filters, name: value });
						}}
					/>
				</div>
				<AddModal onEnd={fetchData} />
				<FilterPopover
					sortBy={filters.sort_by}
					onChange={(value) => {
						setFilters({ ...filters, sort_by: value });
					}}
				/>
			</div>
			<div className="settings-list">
				{loading && <Loader />}
				{!loading && interests.length === 0 && (
					<div className="no-content">{t('No interests found')}</div>
				)}
				{!loading && interests.length > 0 && (
					<ul>
						{interests.map((item) => (
							<li
								key={item._id}
								className={classNames('item', {
									active: popover.id === item._id,
								})}
							>
								<div className="icon bg">
									<Image src={item.image} />
								</div>
								<div className="title" title={item.name}>
									{item.name}
								</div>
								<div className="action">
									<MoreIcon
										onClick={(anchorRef, skipClick) => {
											openPopover(anchorRef, skipClick, item);
										}}
									/>
								</div>
							</li>
						))}
					</ul>
				)}
				{(interests.length > 0 || (interests.length === 0 && filters.page > 1)) && (
					<Paging
						page={filters.page}
						per_page={filters.per_page}
						totals={interests.length}
						onChange={(value) => {
							setFilters({ ...filters, page: value });
						}}
					/>
				)}
			</div>
			<ActionPopover
				{...popover}
				interest={interest}
				onClose={closePopover}
				onEnd={fetchData}
			/>
		</>
	);
};

export default Interests;
