import fetch from '../utils/fetch';
import isDispatch from '../utils/isDispatch';

export const getCategories = async (dispatch, params) => {
	if (!isDispatch(dispatch)) {
		params = dispatch;
	}
	const res = await fetch('GET', '/categories', null, params);
	if (isDispatch(dispatch)) {
		dispatch({
			categories: res.data,
			type: 'BATCH_UPDATE_CATEGORIES',
		});
	} else {
		return res;
	}
};

export const addCategory = (data) => {
	return fetch('POST', '/categories', data);
};

export const updateCategory = (categoryID, data) => {
	return fetch('PUT', `/categories/${categoryID}`, data);
};

export const deleteCategory = (categoryID) => {
	return fetch('DELETE', `/categories/${categoryID}`);
};
