import React, { useState } from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { addInterest } from '../../../api/interest';

import { ReactComponent as PlusIcon } from '../../../images/icons/plus.svg';
import { ReactComponent as ExitIcon } from '../../../images/icons/close.svg';

const AddModal = ({ onEnd }) => {
	const { t } = useTranslation();
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const { register, handleSubmit, errors } = useForm();

	const openModal = () => {
		setModalIsOpen(true);
	};

	const closeModal = () => {
		toast.dismiss();
		setSubmitting(false);
		setModalIsOpen(false);
	};

	const onSubmit = async (data) => {
		try {
			toast.dismiss();
			setSubmitting(true);
			await addInterest(data);
			closeModal();
			onEnd && onEnd();
		} catch (err) {
			setSubmitting(false);
		}
	};

	return (
		<>
			<div className="btn" onClick={openModal}>
				<PlusIcon />
			</div>
			{modalIsOpen && (
				<ReactModal
					className="modal"
					isOpen={true}
					ariaHideApp={false}
					onRequestClose={closeModal}
					overlayClassName="modal-overlay"
					shouldCloseOnOverlayClick={true}
				>
					<header>
						<h1>{t('Add interest')}</h1>
						<span className="exit" onClick={closeModal}>
							<ExitIcon />
						</span>
					</header>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className={classNames('form-group', 'required', { error: errors.name })}>
							<label>{t('Name')}</label>
							<input type="text" name="name" ref={register({ required: true })} />
						</div>
						<div className={classNames('form-group', { error: errors.subtitle })}>
							<label>{t('Description')}</label>
							<textarea
								rows="3"
								maxLength="280"
								placeholder={t('280 characters or less')}
								name="subtitle"
								ref={register({
									maxLength: 280,
								})}
							/>
						</div>
						<div className={classNames('form-group', { error: errors.url })}>
							<label>{t('Image URL')}</label>
							<input type="url" name="image" ref={register} />
						</div>
						<div className="buttons">
							<button type="submit" className="btn primary" disabled={submitting}>
								{t('Save')}
							</button>
							<button type="button" className="btn link cancel" onClick={closeModal}>
								{t('Cancel')}
							</button>
						</div>
					</form>
				</ReactModal>
			)}
		</>
	);
};

export default AddModal;
